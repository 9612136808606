/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    select, call, put,
} from 'redux-saga/effects';

import cookies from '../../../../../app/helpers/common/storage/cookies';

import { getCurrentPageView } from '../TagManager/TagManager-Selectors';
import GoogleAnalyticsInterface from '../../../../../app/helpers/GoogleAnalyticsInterface';
import { getMembershipType } from '../../../Member/ducks/Common/Common-Selectors';
import { getCustomerEmail } from '../../../../../app/pages/Account/state/ducks/Common/Common-Selectors';
import {
    getSSRDeviceType, getLocationType, getValidatedZipcode,
} from '../../../App/App-Selectors';
import {
    getOrderId, getRecipients, getAppliedPromotions,
    getOrderTotalSummary, getOrderTotalShipping, getIfGiftCardApplied,
    getBilling,
} from '../../../Common/Common-Selectors';
import { setClickstreamExperiments } from './ClickStreamEvent-Actions';
import { getClickstreamExperiments } from './ClickStreamEvent-Selectors';
import { getFeatureFlag } from '../../../App/ducks/Config/Config-Selectors';
import useBrowserUUID from '../../../../../app/helpers/useBrowserUUID';

const buildSkuPriceRangeWithCurrency = (skuPriceRange = {}, prices) => {
    const newSkuPriceRange = {};
    const keysArr = Object.keys(skuPriceRange);

    keysArr.forEach((key) => {
        const newSkuArr = [];
        const skuArr = skuPriceRange[key]; // value
        if (Array.isArray(skuArr) && Array.isArray(prices)) {
            skuArr.forEach((sku) => {
                const priceObj = prices.find((price) => price?.type === key && price?.value === sku?.value);
                const newObj = {
                    ...sku,
                    currency: priceObj?.currency || '',
                };
                delete newObj['__typename'];
                newSkuArr.push(newObj);
            });
            newSkuPriceRange[key] = newSkuArr;
        } else {
            newSkuPriceRange[key] = skuArr || [];
        }
    });
    delete newSkuPriceRange['__typename'];
    return newSkuPriceRange;
};

/**
 *
 * @param {object} productsPayload
 * @param {number} productsPerRow
 */
export const generateProductsPayload = (productsPayload, productsPerRow) => {
    const { products, prevProductLength = 0, productPageSize = 12 } = productsPayload;
    if (!products?.length) {
        return null;
    }
    const cleanProducts = [];
    let trimmedProducts = products;
    let pageNumber = 1;

    if (products.length > productPageSize) {
        if (prevProductLength) {
            pageNumber = Math.ceil((products.length - productPageSize) / productPageSize) + 1;
            // only calls with new products
            trimmedProducts = products.slice(prevProductLength || products.length - 12);
        } else {
            trimmedProducts = products.slice(0, productPageSize);
        }
    }

    // build schema
    trimmedProducts.map((product, idx) => {
        const cleanProduct = {};
        cleanProduct.name = product?.name;
        cleanProduct.baseCode = product?.partNumber;
        cleanProduct.skuPriceRange = buildSkuPriceRangeWithCurrency(product?.skuPriceRange, product?.prices);
        cleanProduct.pageNumber = pageNumber;
        cleanProduct.gridNumber = productsPerRow || 4;
        cleanProduct.position = (prevProductLength + idx) + 1;
        return cleanProducts.push(cleanProduct);
    });
    return cleanProducts;
};

export const removeEmptyKeyPairs = (obj, keyToRemove) => JSON.parse(JSON.stringify(obj,
    (key, val) => (key === keyToRemove ? undefined : val)),
);

export function* getGeneratedBrowserUUID() {
    // if browseUUID not present generate new one or return already generated browserUUID from localStorage.
    // named key as `browserUUID` as its already used in EnterpriseId-Helpers.js file used for saving browser UUID in localStorage.
    const browserUUID = yield call(useBrowserUUID);

    return browserUUID || '';
}

export function* buildPagePayload(clickStreamEventPayload) {
    const payload = {};

    if (typeof window !== 'undefined') {
        payload.url = window.location?.href;
        payload.height = window.screen?.height;
        payload.width = window.screen?.width;
    }

    if (clickStreamEventPayload?.type === 'mbp-checkout/ADD_GREETING_CARD_GIFT_MESSAGE' || clickStreamEventPayload?.type === 'MFE/MBP-CHECKOUT/ENTERPRISE_SAVED_RECIPIENT_GIFT_MESSAGE') {
        payload.type = 'shipping';
        payload.title = 'Gift Message';

        return payload;
    }

    // added referrer to page object schema for capturing organic orders from search engines.
    if ((typeof document !== 'undefined') && clickStreamEventPayload?.type === 'mbp-tag-manager/TRACK/PAGE/VIEW') {
        payload.referrer = document?.referrer;
    }

    if (clickStreamEventPayload?.type === 'ADDTOCART/ADD_ITEM_TO_CART_SUCCESS') {
        if (clickStreamEventPayload?.payload?.page?.type) {
            payload.title = clickStreamEventPayload?.payload?.page?.title;
            payload.type = clickStreamEventPayload?.payload?.page?.type;
        } else {
            const { products = [] } = clickStreamEventPayload?.payload?.metaData;
            const firstProduct = products[0];
            const pageType = yield select(getCurrentPageView);
            payload.title = firstProduct?.name;
            payload.type = pageType?.pageType;
        }
        return payload;
    }

    const {
        type = '', name = '', title = '',
    } = clickStreamEventPayload?.payload?.page || {};
    let pageType = type;
    // if pageType undefined because not invoked w/ payload (none page view events) - call selector
    if (!type) {
        const temp = yield select(getCurrentPageView);
        pageType = temp?.pageType || 'unknown';
    }

    if (payload.url?.includes('/checkout/') && type !== 'native-interstitial-page') {
        // if page is in checkout, take name > type
        pageType = name;
        if (payload.url?.includes('/cart')) {
            pageType = 'cart';
        } else if (payload.url?.includes('/shipping/')) {
            pageType = 'shipping';
        } else if (payload.url?.includes('/payment/')) {
            pageType = 'payment';
        } else if (payload.url?.includes('/review-order/')) {
            pageType = 'review_order';
        } else if (payload.url?.includes('/order-confirmation/')) {
            pageType = 'order_confirmation';
        }
    }

    if (type === 'order-confirm') {
        pageType = 'order_confirmation';
    }

    // added condition for pagetype when redirect happened after placing order or tried to back to place order by using browsers back button.
    if (clickStreamEventPayload?.type === 'mbp-tag-manager/TRACK/PAGE/VIEW' && type === 'checkout' && ['/'].indexOf(window?.location?.pathname) >= 0) {
        pageType = 'home';
    }

    if (pageType) payload.type = pageType;
    if (title) payload.title = title;

    return payload;
}

export function removeEmptySchemaData(obj) {
    const copyObj = obj;
    const fields = Object.keys(obj);
    fields.forEach((field) => {
        if (obj[field] && typeof obj[field] === 'object') {
            const ObjectKeys = Object.keys(obj[field]);
            if (ObjectKeys?.length) {
                ObjectKeys.map((item) => !obj[field][item] && delete copyObj[field][item]);
            }
            if (JSON.stringify(copyObj[field]) === '{}') {
                delete copyObj[field];
            }
            if (ObjectKeys.length === 0) {
                delete copyObj[field];
            }
        }
        if (Array.isArray(obj[field])) {
            if (obj[field].length < 1) {
                delete copyObj[field];
            }
        }
    });
    return copyObj;
}

export function* buildUserPayload() {
    const GAI = new GoogleAnalyticsInterface();
    const { gaSessionId, gaClientId } = GAI.getGAClientAndSessionId();

    const customerType = yield select(getMembershipType);
    const emailId = yield select(getCustomerEmail);
    const shopperManagerId = cookies.get('ShopperManagerEnterprise');

    return {
        shopperManagerId, customerType, emailId, gaSessionId, gaClientId,
    };
}

export function* buildExperimentsPayload() {
    const previousExperiments = yield select(getClickstreamExperiments);

    return [...previousExperiments];
}

export function buildCategoryPayload(clickStreamEventPayload) {
    // On a category page
    if (clickStreamEventPayload.type === 'mbp-tag-manager/TRACK/PAGE/VIEW' && (clickStreamEventPayload?.payload?.page?.type?.toLowerCase() === 'category' || clickStreamEventPayload?.payload?.page?.type?.toLowerCase() === 'category-template')) {
        const trackCategoryData = clickStreamEventPayload?.payload?.page?.dataLayer.find((obj) => obj.action === 'track_category_data');
        const occasion = trackCategoryData?.categoryOccasion;
        const { code: id = '', categoryName: name = '' } = clickStreamEventPayload.payload.page;
        return { id, name, occasion };
    }

    // From a category page
    const location = clickStreamEventPayload.payload?.page?.location;
    if (clickStreamEventPayload.type === 'mbp-tag-manager/TRACK/PAGE/VIEW' && location?.state?.fromCategoryPage) {
        const { categoryId } = location.state;
        if (categoryId) {
            return {
                id: categoryId,
            };
        }
    }

    if (clickStreamEventPayload.type === 'CLICKSTREAM/PRODUCT_IMPRESSION' && clickStreamEventPayload.payload?.category) {
        const { id } = clickStreamEventPayload.payload?.category;
        let name = clickStreamEventPayload.payload?.category?.name || '';
        name = name.split('|')[0];
        return { id, name };
    }
    return {};
}

export function* buildDevicePayload() {
    let userAgent;
    if (typeof navigator !== 'undefined') {
        userAgent = navigator?.userAgent;
    }

    let height; let width;
    if (typeof window !== 'undefined') {
        height = window?.screen?.availHeight;
        width = window?.screen?.availWidth;
    }

    // const appVersion = // TODO how do we get this?
    const appName = 'pwa';
    const platform = 'web';
    const deviceType = yield select(getSSRDeviceType);
    return {
        appName,
        userAgent,
        height,
        width,
        platform,
        deviceType,
    };
}

const getScreenWidth = () => {
    if (typeof document !== 'undefined') {
        return document.documentElement?.clientWidth || 0;
    }
    return 0;
};

export function buildProductPerRow(payload) {
    const { mobileBreakpoint, productsPerRow } = payload;
    const isMobileView = getScreenWidth() < mobileBreakpoint;
    return isMobileView ? 2 : productsPerRow;
}

function buildOccasionsPayload(productSkus = []) {
    return productSkus.reduce((occasion, product) => {
        if (product?.occasion?.length > 0 && product.occasion[0] !== null) {
            return occasion.concat(product.occasion);
        }
        return occasion;
    }, []).filter((item, index, originalArray) => originalArray.indexOf(item) === index); // removed duplicates from occasion array.
}

function buildCategoriesPayload(productSkus = []) {
    const formattedCategoryList = [];
    const categoryHierarchiesToSkip = ['Master catalog', 'Secondary catalog'];
    productSkus.reduce((category, product) => {
        if (product?.categoryHierarchies?.length > 0 && product?.categoryHierarchies[0] !== null) {
            product.categoryHierarchies.forEach((categories) => {
                const categoryHierarchyTreeList = [];
                categories.categoryHierarchyTreeList.forEach((categoryHierarchy) => {
                    if (!categoryHierarchiesToSkip.includes(categoryHierarchy.label) && !category.includes(categoryHierarchy.label)) {
                        category.push(categoryHierarchy.label);
                        categoryHierarchyTreeList.push(categoryHierarchy.label);
                    }
                });
                if (categoryHierarchyTreeList.length) formattedCategoryList.push(categoryHierarchyTreeList.join(' | '));
                return [];
            });
        }
        return category;
    }, []);
    return formattedCategoryList.filter((item, index, originalArray) => originalArray.indexOf(item) === index); // removed duplicates from occasion array.
}

export function buildProductsPayload(clickStreamEventPayload) {
    if (clickStreamEventPayload.type === 'mbp-tag-manager/TRACK/PAGE/VIEW' && clickStreamEventPayload?.payload?.page?.type?.toLowerCase() === 'product') {
        const {
            code: baseCode = '', name = '', dataLayer = [],
        } = clickStreamEventPayload.payload.page;
        let selectedSku = '';
        let productSkus = [];

        if (dataLayer.length) {
            const filteredProductData = dataLayer.find((item) => item?.action === 'track_product_data');
            if (filteredProductData && filteredProductData?.product) {
                selectedSku = filteredProductData?.product?.selectedSku;
                productSkus = filteredProductData?.product?.productSkus;
            }
        }

        const occasions = buildOccasionsPayload(productSkus);
        const categories = buildCategoriesPayload(productSkus);

        const productPayload = [{
            baseCode, partNumber: selectedSku, name, ...(occasions.length > 0 ? { occasions } : null), ...(categories.length > 0 ? { categories } : null),
        }];
        if (!productPayload[0]?.partNumber?.trim().length) {
            delete productPayload[0]?.partNumber;
        }
        return productPayload;
    }
    if (clickStreamEventPayload.type === 'ADDTOCART/ADD_ITEM_TO_CART_SUCCESS') {
        return clickStreamEventPayload?.payload?.metaData?.products || [];
    }
    return [];
}

function getPriceRangeFilters(productsPayload) {
    const priceRangeFacet = productsPayload?.priceRangeData?.entries || [];
    const priceRangeFilter = productsPayload?.variables?.productOptions?.priceRangeFilter?.ranges || [];

    const pricesValues = priceRangeFilter.map((ele) => {
        const priceRange = priceRangeFacet.find((prices) => (prices?.to === ele?.to && prices?.from === ele?.from));
        return priceRange?.value;
    });
    return pricesValues || [];
}

export function* buildProductFiltersPayload(clickStreamEventPayload) {
    const payload = {};
    const { searchTerm = '', type = '' } = clickStreamEventPayload?.payload?.page;
    const productsPayload = clickStreamEventPayload?.payload?.productsPayload || {};

    const guidedNavLocation = yield select(getValidatedZipcode);
    const guidedNavLocationType = yield select(getLocationType);

    const priceRangeFilters = getPriceRangeFilters(productsPayload);
    let facets = productsPayload.variables?.productOptions?.facets || [];
    facets = facets.map((facet) => facet?.replace('|', '-'));

    if (type === 'search' && searchTerm) {
        payload.searchTerm = searchTerm;
    }
    if (type === 'category' || type === 'search') {
        payload.guidedNavLocation = guidedNavLocation;
        payload.guidedNavLocationType = guidedNavLocationType;
        payload.facets = facets?.length ? facets : null;
        payload.priceRangeFilters = priceRangeFilters?.length ? priceRangeFilters : null;
        payload.orderBy = productsPayload?.variables?.productOptions?.orderBy;
    }
    return payload;
}

export function* buildOrderPayload() {
    const cartId = yield select(getOrderId);
    let payload = {};
    if (Array.isArray(cartId) && cartId[0]) {
        payload = { cartId: cartId[0] };
    }
    if (typeof cartId === 'string' && cartId) {
        payload = { cartId };
    }
    return payload;
}

const buildProductsFromRecip = (recips) => {
    const products = [];

    recips.forEach((recipient) => {
        recipient.cartItems?.forEach((cartItem) => {
            products.push({
                baseCode: cartItem.product?.parentProductCode,
                price: cartItem.itemInfo?.price,
                name: cartItem.product?.parentProductName,
                partNumber: cartItem.product?.productCode,
                quantity: cartItem?.itemInfo?.quantity,
            });
            // push add-ons if available
            if (cartItem.addons?.length > 0) {
                cartItem.addons.forEach((addon) => {
                    products.push({
                        baseCode: addon.product?.parentProductCode,
                        partNumber: addon.product?.productCode,
                        name: addon.product?.parentProductName,
                        addon: true,
                        price: addon.itemInfo?.price,
                        quantity: addon.itemInfo?.quantity,
                    });
                });
            }
        });
    });
    return products;
};

export const buildCustomEventPayload = (payload) => {
    const customEvent = {};

    if (payload?.action) {
        customEvent.action = payload.action;
    }
    if (payload?.label) {
        customEvent.label = payload.label;
    }

    if (payload?.category) {
        customEvent.category = payload.category;
    }

    if (payload?.link) {
        customEvent.link = payload.link;
    }

    return customEvent;
};

export function* buildSalesforceExperimentPayload(payload) {
    const previousExperiments = yield select(getClickstreamExperiments);

    if (payload?.salesforceResponse?.campaign?.campaignResponses?.length > 0) {
        const campaign = payload?.salesforceResponse?.campaign?.campaignResponses[0];
        const experiment = {};
        experiment.id = campaign?.experienceId;
        experiment.name = campaign?.experienceName;
        experiment.campaignName = campaign?.campaignName;
        experiment.campaignId = campaign?.campaignId;
        experiment.variant = campaign?.userGroup;
        experiment.source = 'salesforce';
        experiment.promotionId = campaign?.payload?.promotions?.[0]?.id;
        experiment.promotionName = campaign?.payload?.promotions?.[0]?.attributes?.name?.value;
        experiment.assetContentZoneOrTag = campaign?.payload?.assetContentZoneOrTag;

        const filteredExps = previousExperiments.filter((exp) => exp?.id !== experiment?.id);
        filteredExps.unshift(experiment);
        yield put(setClickstreamExperiments(filteredExps));

        return [experiment];
    }

    // for experiment service which was formatted already from gql
    if (payload?.experiments?.length > 0) {
        return payload.experiments;
    }

    if (payload?.action === 'customer-input') {
        return previousExperiments;
    }

    return null;
}

export const buildSalesforceProductsPayload = (payload) => {
    if (payload?.productFromControl && payload?.action === 'Click') {
        return [{
            baseCode: payload?.productFromControl?.partNumber,
        }];
    }

    let products = [];
    // returns recentlyViewed recs depending on which carousel is rendering
    const salesforceProducts = payload?.displayType === 'BOTH'
        ? [...payload?.salesforceResponse?.products, ...payload?.salesforceResponse?.productRecs]
        : payload?.salesforceResponse?.products || payload?.salesforceResponse?.productRecs;

    if (payload?.action === 'Click' && payload?.partNumber) {
        products = [{ baseCode: payload?.partNumber }];
    } else if (Array.isArray(salesforceProducts)) {
        products = salesforceProducts.map((product) => {
            const tempObj = { baseCode: product?.partNumber || '', name: product?.name || '' };
            return tempObj;
        });
    }

    return products;
};

export function* buildOrderCompletePayload(paymentInfo) {
    const recipients = yield select(getRecipients);
    const billingInfo = yield select(getBilling);
    const orderSummary = yield select(getOrderTotalSummary);
    const shipping = yield select(getOrderTotalShipping);
    const promotionsList = yield select(getAppliedPromotions);
    const orderId = yield call(buildOrderPayload);
    const isGiftCardApplied = yield select(getIfGiftCardApplied);
    const isPromoCodeApplied = promotionsList?.appliedPromotions?.length > 0;

    const promoCode = isPromoCodeApplied ? promotionsList.appliedPromotions[0].name : null;

    const order = {
        cartId: orderId?.cartId,
        orderTotal: orderSummary.orderTotalBalance,
        tax: orderSummary.totalTax,
        shipping,
        paymentMethod: paymentInfo?.paymentMethod,
        promoCode,
    };

    if (isGiftCardApplied) order.giftCardAmount = parseFloat(orderSummary.totalGiftCards);

    // Used Math.abs predefined JS method for converting negative to positive values.
    if (isPromoCodeApplied) order.promoCodeAmount = parseFloat(orderSummary.totalAdjustments);

    const page = {
        type: 'order-confirm',
        title: 'Order Confirmation',
    };

    const products = buildProductsFromRecip(recipients);

    return {
        products,
        order,
        payload: { page },
        billingEmailId: billingInfo.email,
        phone: billingInfo.phone,
    };
}

export const buildRecipientsPayload = (trackEventPayload) => {
    const payload = trackEventPayload?.payload;

    // as we only need recipient payload in find a gift fast module.
    if (payload?.action && payload?.action !== 'customer-input') return [];

    const {
        firstName, lastName, relationship, occasionCode, zipCode,
    } = payload;

    const recipientAttributes = {};
    if (firstName) recipientAttributes.firstName = firstName;
    if (lastName) recipientAttributes.lastName = lastName;
    if (relationship) recipientAttributes.relationship = relationship;
    recipientAttributes.occasionCode = occasionCode;
    if (zipCode) recipientAttributes.zipCode = zipCode;

    // below payload used for capture values from new find a gift fast module.
    const recipients = [recipientAttributes];

    return recipients;
};

export function* buildGiftmessagePayload(giftMessageEventPayload) {
    const { greetingData, recipientAddress } = giftMessageEventPayload;

    const isChatGPTEnabled = yield select(getFeatureFlag('is-chat-gpt-enabled'));

    const chatGPTData = greetingData?.data?.chatGPTMessageData || {};
    const orderCartIdKey = Object.keys(chatGPTData)?.[0] || '';
    const chatGPTDataMsgData = chatGPTData?.[orderCartIdKey] || {};

    if (chatGPTDataMsgData?.giftMessage?.length > 0 && isChatGPTEnabled) {
        return {
            occasion: chatGPTDataMsgData?.occasion || '',
            recipient: chatGPTDataMsgData?.recipientName || '',
            giftMessage: chatGPTDataMsgData?.giftMessage?.[0] || '',
            source: 'AI-chatGPT',
            tone: chatGPTDataMsgData?.tone || '',
        };
    }

    const giftMessage = greetingData?.data?.message?.text || greetingData?.data?.message;

    return {
        occasion: greetingData?.data?.occasionName || '',
        recipient: recipientAddress?.firstName || '',
        giftMessage: giftMessage || '',
        source: 'USER',
        tone: '',
    };
}

export const buildLoginMethodCustomPayload = (referenceLoginMethod) => {
    const customEvent = {};
    const loginMethodCollection = [
        { loginMethod: 'Email', loginMethodIdentifier: 'T0-Direct-MDM-DB' },
        { loginMethod: 'Email', loginMethodIdentifier: 'auth0' },
        { loginMethod: 'Google', loginMethodIdentifier: 'google-oauth2' },
        { loginMethod: 'Facebook', loginMethodIdentifier: 'facebook' },
        { loginMethod: 'Apple', loginMethodIdentifier: 'apple' },
        { loginMethod: 'Passwordless', loginMethodIdentifier: 'email' },
        { loginMethod: 'Passwordless', loginMethodIdentifier: 'sms' },
    ];

    const selectedLoginMethodObject = loginMethodCollection.find((loginMethodItem) => loginMethodItem.loginMethodIdentifier === referenceLoginMethod);

    if (selectedLoginMethodObject?.loginMethod) {
        customEvent.loginMethod = selectedLoginMethodObject.loginMethod;
    }

    return customEvent;
};

export default {
    buildPagePayload,
    buildUserPayload,
    buildExperimentsPayload,
    buildCategoryPayload,
    buildProductFiltersPayload,
    buildOrderPayload,
    buildOrderCompletePayload,
    buildProductPerRow,
    buildGiftmessagePayload,
    removeEmptySchemaData,
    buildRecipientsPayload,
    buildLoginMethodCustomPayload,
};
