/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import loadable from '@loadable/component';
import { bool, object } from 'prop-types';
import mbpLogger from 'mbp-logger';
import { useHistory } from 'react-router';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import ErrorBoundary from '../app/components/ErrorBoundary/ErrorBoundary';

import MicroFrontendClient from '../app/components/MicroFrontendClient/MicroFrontendClient';

import Auth from '../app/pages/Auth/Auth';

const Loader = ({ error }) => {
    if (error) {
    // return (
    //     <div>
    //         Error!
    //         <button type="button" onClick={retry}>Retry</button>
    //     </div>
    // );
        mbpLogger.logError({
            function: 'publicRoutes',
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            jsError: error,
            message: 'Checkout loadable route failed',
        });
    }

    return <div />;
};

Loader.propTypes = {
    error: object,
};
Loader.defaultProps = {
    error: null,
};

const LoadableCoreBrowsing = loadable(() => import(/* webpackChunkName: "CoreBrowsingContainer" */ '../app/components/GraphqlComponents/GraphqlCoreBrowsing/CoreBrowsingContainer'));
const LoadableAccount = loadable(() => import(/* webpackChunkName: "Account" */ '../app/pages/Account/Account'));
const LoadableEntranceRoute = loadable(() => import(/* webpackChunkName: "EntranceRoute" */ '../app/components/GraphqlComponents/EntranceRoute/EntranceRoute'));
const InterstitialPageContainer = loadable(() => import(/* webpackChunkName: "InterstitialPageContainer" */ '../app/components/GraphqlComponents/InterstitialPage/InterstitialPageContainer'));

// TODO: Do not restore me until NextJS has been implemented
// const LoadableCoreBrowsing = loadable(() => import("CoreBrowsing" */ '../app/pages/CoreBrowsing/CoreBrowsing');

// Returns the Checkout MFE host
// - Defaults to relative "checkout_app" path (Fastly)
// - Allows env var override for local development
const getCheckoutAppHost = () => {
    let host = '';

    if (typeof window !== 'undefined') {
        host = `//${window.location.hostname}/checkout_app`;

        if (process.env.REACT_APP_CHECKOUT_MFE_HOST) {
            host = process.env.REACT_APP_CHECKOUT_MFE_HOST;
        }
    }

    return host;
};

const getAccountAppHost = () => {
    let host = '';
    if (typeof window !== 'undefined') {
        host = `//${window.location.hostname}/account_app`;
        if (process.env.REACT_APP_ACCOUNT_MFE_HOST) {
            host = process.env.REACT_APP_ACCOUNT_MFE_HOST;
        }
    }
    return host;
};

export const getPublicRoutes = (props = {}) => {
    const LoadableCheckoutRoute = ({
        store,
        apolloClient,
    }) => {
        const history = useHistory();
        let ldClient = null;
        if (typeof window !== 'undefined') {
            ldClient = useLDClient();
        }

        return (
            <MicroFrontendClient
                clientName="mbp-checkout"
                host={getCheckoutAppHost()}
                store={store}
                history={history}
                apolloClient={apolloClient}
                ldClient={ldClient}
            />
        );
    };
    LoadableCheckoutRoute.propTypes = {
        store: object.isRequired,
        apolloClient: object.isRequired,
    };

    const LoadableAccountRoute = ({
        store,
        apolloClient,
    }) => {
        const { isAccountMFEEnabled, accountRoutesMFEObject } = props;

        /*
            {
                "address-book": false,
                "billing-address": false,
                "orders": true,
                "profile": false,
                "email-preference": true,
                "account": false,
                "passport": false,
                "subscriptions": false,
                "change-password": false,
                "gift-list": false,
                "change-email": false,
                "gifthistory-home-gift-baskets": false,
            }
        */

        const history = useHistory();
        const splitUrl =  history?.location?.pathname.split('/');
        let accountPath = 'account';

        if (splitUrl[1].includes('gifthistory')) {
            accountPath = splitUrl[1];
        } else if (splitUrl[2]) {
            accountPath = splitUrl[2];
        }

        const isAccountRouteOnMFE = accountRoutesMFEObject[accountPath];
        if (isAccountMFEEnabled && isAccountRouteOnMFE) {
            return (
                <ErrorBoundary
                    boundaryName="Account_MFE Error Boundary"
                    errorMessage="Something unexpected occurred, please try again."
                >
                    <MicroFrontendClient
                        clientName="mbp-account"
                        host={getAccountAppHost()}
                        store={store}
                        history={history}
                        apolloClient={apolloClient}
                        isUsingMFE
                    />
                </ErrorBoundary>
            );
        }

        return <LoadableAccount />;
    };
    LoadableAccountRoute.propTypes = {
        store: object.isRequired,
        apolloClient: object.isRequired,
        isAccountMFEEnabled: bool.isRequired,
        accountRoutesMFEObject: object.isRequired,
    };

    return [
        {
            path: '/account/:page?',
            Component: LoadableAccountRoute,
        },
        {
            path: '/gifthistory-home-gift-baskets/:params?', // H&D
            Component: LoadableAccountRoute,
        },
        {
            path: '/gifthistory-home-cookies/:params?', // CCO
            Component: LoadableAccountRoute,
        },
        {
            path: '/gifthistory-home-steaks/:params?', // STY
            Component: LoadableAccountRoute,
        },
        {
            path: '/gifthistory-home-bakery/:params?', // WLF
            Component: LoadableAccountRoute,
        },
        {
            path: '/checkout/:page/:orderId',
            Component: LoadableCheckoutRoute,
        },
        {
            path: '/checkout/:page',
            Component: LoadableCheckoutRoute,
        },
        {
            path: '/auth/:page',
            Component: Auth,
        },
        {
            path: '/Entrance',
            Component: LoadableEntranceRoute,
        },
        {
            path: '/sign-in',
            Component: InterstitialPageContainer,
        },
        {
            path: '/*',
            Component: LoadableCoreBrowsing,
        },
    ];
};

export default {};
