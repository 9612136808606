/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import {
    takeEvery, call, put, fork, select, take,
} from 'redux-saga/effects';

import mbpLogger from 'mbp-logger';
import mbpUtil from 'mbp-api-util';
import { duck as tmDuck } from '../../../TagManager';

import {
    clearProfileDetails,
    loadProfileDetails,
    setProfileEmailId,
    setMembershipTypeLoading,
} from '../Profile/Profile-Actions';

import * as authActions from './Auth-Actions';

import { commonSelectors } from '../Common';

import * as helper from './helper/helper';
import {
    getIsPassportOnlyItemOnCart,
    getIsDonationOnlyItemOnCart,
} from '../../../Checkout/ducks/Common/Common-Selectors';

import { clearWallet } from '../Wallet/Wallet-Actions';
import { clearAddressBook } from '../AddressBook/AddressBook-Actions';
import { enterpriseIdLoading, enterpriseIdUserSegmentsLoaded } from '../EnterpriseId/EnterpriseId-Actions';
import { loadCartCount, clearOrderId } from '../../../Order/Order-Actions';
import {
    getAccessTokenInSession, getWindowLocation, removeTokenLocalStorage, getGdprQuery,
} from './Plugin/auth/helpers';
import {
    setCookie,
} from './helper/helper';
import * as tagManagerSelectors from '../../../TagManager/ducks/TagManager/TagManager-Selectors';
import * as auth from './Plugin/auth/auth';
import { getUserRole, getUserType } from '../Common/Common-Selectors';
import { billingClearUpdateFields } from '../../../Checkout/ducks/Payment/ducks/BillingAddressForm/BillingAddressForm-Actions';
import { getEnterpriseId } from '../EnterpriseId/EnterpriseId-Selectors';
import getCookie from '../../../../../app/helpers/common/storage/cookieStorage';
import { paymentClearUpdateFields, setPaymentMethod } from '../../../Checkout/ducks/Payment/ducks/PaymentForm/PaymentForm-Actions';
import { emitCustomTrackingEvent } from '../../../TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import { getFeatureFlag } from '../../../App/ducks/Config/Config-Selectors';

export function* checkJWT() {
    let JWT_TOKEN = '';

    const logOrderId = yield select(commonSelectors.getOrderId);

    try {
        JWT_TOKEN = yield call(auth.getAccessTokenSafely);
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'Auth-Operations.js',
            function: 'checkJWT',
            jsError: ex,
            message: 'checkJWT Failed',
            orderId: logOrderId,
        });
    }

    return JWT_TOKEN;
}

function* workerSagaRouteListener({ path }) {
    if (path !== '/auth/login' && path !== '/auth/logout' && path !== '/auth/callback' && path !== '/auth/callbackguest' && path !== '/sign-in') {
        yield put(authActions.logLastVisitedPage(path));
    }
}

function* watcherSagaRouteListener() {
    yield takeEvery(authActions.addRouteChanges().type, workerSagaRouteListener);
}

function* triggerCustomEvent(checkoutInterstitialData) {
    yield put(emitCustomTrackingEvent({
        action: 'Impression',
        salesforceResponse: checkoutInterstitialData,
        page: { type: 'native-interstitial-page' },
        eventSubType: 'campaign-tracking',
    }));
}

function* workerInitinterstitial(action) {
    let savedEmailId = '';

    const localStorageUserInfo = yield call(helper.getTokenLocalStorage, 'userInfo');

    if (localStorageUserInfo) {
        const userInfo = JSON.parse(localStorageUserInfo);
        savedEmailId = userInfo?.profile?.email || '';

        if (savedEmailId) {
            yield put(setProfileEmailId(savedEmailId));
        }
    }

    const { history, containsSubscription } = action;
    const brand = yield select(commonSelectors.getBrand);
    const orderId = yield select(commonSelectors.getOrderId);
    const profile = yield select(commonSelectors.getProfileInfo);
    const isNativeInterstitialLoginEnabled = yield select(getFeatureFlag('is-native-interstitial-page-enabled'));
    const isAuthenticated = yield select(commonSelectors.getIsAuthenticatedStatus);
    const isPassportOnlyItemInCart = yield select(getIsPassportOnlyItemOnCart);
    const isDonationOnlyItemInCart = yield select(getIsDonationOnlyItemOnCart);
    let routeBack = `/checkout/shipping/${orderId}`;

    if (isPassportOnlyItemInCart || isDonationOnlyItemInCart || history.location.pathname?.includes('/checkout/shipping')) {
        /* Redirect to payment page, if user is redirected to interstital page from shipping page */
        routeBack = `/checkout/payment/${orderId}`;
        yield put(authActions.logLastVisitedPage(routeBack));
    }
    const isNewCheckoutInterstitialEnabled = sessionStorage.getItem('isNewCheckoutInterstitialEnabled');
    const checkoutInterstitialData = JSON.parse(sessionStorage.getItem('checkoutInterstitialData'));
    if ((!profile.email || containsSubscription) && !isAuthenticated && (!savedEmailId || containsSubscription)) {
        if (!containsSubscription && isNativeInterstitialLoginEnabled && isNewCheckoutInterstitialEnabled === 'true') history.push(`/sign-in?routeBack=${routeBack}`);
        else if (!containsSubscription && isNativeInterstitialLoginEnabled && isNewCheckoutInterstitialEnabled !== 'true') {
            if (checkoutInterstitialData && Object.keys(checkoutInterstitialData).length > 0) triggerCustomEvent(checkoutInterstitialData);
            history.push(`/checkout/shipping/${orderId}`);
        } else {
            const pageHost = yield call(getWindowLocation);

            const {
                code,
                storeId,
                catalogId,
            } = brand;

            if (checkoutInterstitialData && Object.keys(checkoutInterstitialData).length > 0) triggerCustomEvent(checkoutInterstitialData);
            yield call(auth.loginWithInterstital, {
                brandName: code,
                'ext-brand': code,
                'ext-is-guest': containsSubscription ? 'N' : 'Y',
                'ext-cart-url': `${pageHost}/checkout/cart/${orderId}`,
                'ext-gdpr-value': getGdprQuery()?.gdprValue,
                'ext-last-visited-page': `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
                routeBack,
                guestChkOutCORD: containsSubscription ? 'N' : 'Y',
                SaveEmailGuestCheckoutCmdURL: `${pageHost}/auth/callbackguest?guestuser`,
                ShippingPage: `${pageHost}/checkout/shipping/${orderId}`,
                ShoppingCartPage: `${pageHost}/checkout/cart/${orderId}`,
                ContinueToShop: pageHost,
                homePageURL: pageHost.replace(/\w*:*\/\/*/g, ''), // regex to emiminate http:// and https:// from the host path.
                storeId,
                catalogId,
            });
        }
    } else {
        if (checkoutInterstitialData && Object.keys(checkoutInterstitialData).length > 0) triggerCustomEvent(checkoutInterstitialData);
        history.push(`/checkout/shipping/${orderId}`);
    }
}

function* watcherInitinterstitial() {
    yield takeEvery(authActions.triggerInterstitialLogin().type, workerInitinterstitial);
}

function* workerLogGuestSession(action) {
    const {
        accessToken,
        user,
        isAuthenticated,
    } = action.data;

    let userData = user;

    // We use the email becuase when is password less firstname and lastname are empty
    if (!user.email) {
        userData = {
            firstName: '',
            lastName: '',
            userRole: '',
            contactId: '',
            email: '',
        };
    }

    yield put(loadProfileDetails(accessToken, userData));

    yield put(authActions.setAuthDetails({
        accessToken,
        userType: 'G',
        isAuthenticated,
    }));

    yield put(authActions.logUserSessionReady({
        accessToken,
        userType: 'G',
        isAuthenticated: false,
        ...userData,
    }));
}

function* watcherLogGuestSession() {
    yield takeEvery(authActions.logGuestSession().type, workerLogGuestSession);
}

function* workerLogHydraidUserSession(action) {
    const {
        accessToken,
        user,
        isAuthenticated,
    } = action.data;

    const userType = isAuthenticated ? 'R' : 'G';

    let userData = user;

    // We use the email becuase when is password less firstname and lastname are empty
    if (!user.email) {
        userData = {
            firstName: '',
            lastName: '',
            userRole: '',
            contactId: '',
            email: '',
        };
    }

    yield put(loadProfileDetails(accessToken, userData));

    yield put(authActions.setAuthDetails({
        accessToken,
        userType,
        isAuthenticated,
    }));

    yield put(authActions.logUserSessionReady({
        accessToken,
        userType,
        isAuthenticated,
        ...userData,
    }));
}

function* watcherLogHydraidUserSession() {
    yield takeEvery(authActions.logHydraidUserSession().type, workerLogHydraidUserSession);
}

function* workerUserLoginSuccess(action) {
    const {
        user,
        isAuthenticated,
        accessToken,
    } = action.data;

    yield put(enterpriseIdLoading());
    yield put(setMembershipTypeLoading());
    yield put(loadProfileDetails(accessToken, user));

    const { XAtt3: passportFlag = null, XAtt4: passportStartDate = null, XAtt5: passportEndDate = null } = user;
    const profile = {
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        loginsCount: user.loginsCount || '',
        contactId: user.contactId || '',
        email: user.email || '',
        passportFlag,
        passportStartDate,
        passportEndDate,
    };

    yield call(setCookie, 'persisted_user_profile', JSON.stringify(profile), 2);

    yield put(authActions.setAuthDetails({
        accessToken,
        userType: 'R',
        isAuthenticated,
    }));

    yield put(authActions.logUserSessionReady({
        accessToken,
        userType: 'R',
        isAuthenticated,
        ...user,
    }));

    yield put(authActions.logUserLoginInSessionReady());
}

function* watcherUserLoginSuccess() {
    yield takeEvery([
        authActions.logUserLoggedInSuccess().type,
    ], workerUserLoginSuccess);
}

function* workerClearAuthDependentState(action) {
    const forceClear = action?.data?.forceClear;

    yield put(clearProfileDetails());

    yield put(authActions.setAuthDetails({
        accessToken: '',
        userType: '',
        isAuthenticated: false,
    }));

    yield put(clearOrderId());

    yield put(loadCartCount(0));

    yield put(clearWallet());

    yield put(clearAddressBook());

    yield put(paymentClearUpdateFields());

    yield put(billingClearUpdateFields());

    yield put(enterpriseIdUserSegmentsLoaded(null));

    const accessTokenInSession = yield call(getAccessTokenInSession);

    if (accessTokenInSession === '') {
        yield call(removeTokenLocalStorage, 'userInfo');
    }

    const jwtTest = mbpUtil.testJWT(accessTokenInSession);

    if (!jwtTest?.isGuest || !jwtTest?.isValid || jwtTest?.isExpired || forceClear) {
        yield call(removeTokenLocalStorage, 'userInfo');
    }
}

function* watcherClearAuthDependentState() {
    yield takeEvery(authActions.clearAuthDependentState().type, workerClearAuthDependentState);
}

function* workerUserLogoutInvoked() {
    yield put(setPaymentMethod({ id: 'CreditCard', name: 'CreditCard' }));
    yield put(billingClearUpdateFields());

    yield put(authActions.clearAuthDependentState());

    const LoggedOutBy = 'Global Nav Widget';
    const logOutEventObj = {
        eventName: 'user_logout',
        eventCategory: 'Logged Out',
        eventAction: 'User Successfully Logged Out',
        eventLabel: LoggedOutBy,
    };

    yield put(tmDuck.actions.trackEvent(logOutEventObj));
}

function* watcherUserLogoutInvoked() {
    yield takeEvery(authActions.logUserLoggedOut().type, workerUserLogoutInvoked);
}

function* buildUserData() {
    const memberProfile = yield select(tagManagerSelectors.getMemberProfile) || '';
    const userId = yield select(tagManagerSelectors.getMemberUserId) || '';
    const enterpriseId = yield select(getEnterpriseId);
    // determine R, G from userType - P from userRole -> set a single value as userType in DL
    const ut = yield select(getUserType);
    const userRole = yield select(getUserRole);
    let userType = userRole || ut || 'G';
    // allow userType to be overriden from persisted data
    const persistedUserProfile = getCookie('persisted_user_profile');
    if (userType === 'G' && persistedUserProfile?.membershipType && persistedUserProfile.membershipType !== 'G') {
        userType = persistedUserProfile.membershipType;
    }

    const user = {
        ...memberProfile,
        userType,
        userId,
        enterpriseId,
    };

    return user;
}

function* updateDataLayer() {
    // Add userData to data layer
    const user = yield call(buildUserData) || null;
    if (typeof window !== 'undefined') {
        window.mbpDataLayerView = { ...window.mbpDataLayerView  || {} };
        window.mbpDataLayerView.customer_id = user?.email || '';
        window.mbpDataLayerView.customer_eid = user?.enterpriseId || '';
        window.mbpDataLayerView.customer_firstName = user?.firstName || '';
        window.mbpDataLayerView.customer_lastName = user?.lastName || '';
        window.mbpDataLayerView.customer_email = user?.email || '';
        window.mbpDataLayerView.customer_contactId = user?.contactId || '';
        window.mbpDataLayerView.customer_userRole = user?.userRole || '';
        window.mbpDataLayerView.customer_userType = user?.userType || '';
    }
}

function* workerTrackUserSignedIn(action) {
    const {
        user,
        signInMethod,
    } = action.data;

    // SigIn Tagging
    const SignedInBy = 'Global Nav Widget';
    // Track Create Account (loginsCount of 1)
    if (user && user.loginsCount === 1) {
        yield put(tmDuck.actions.trackEvent({
            eventName: 'user_register',
            eventCategory: 'Register',
            eventAction: 'User Successfully Created Account',
            eventLabel: SignedInBy,
            customer_id: user?.email || '',
            customer_firstName: user?.firstName || '',
            customer_lastName: user?.lastName || '',
            customer_contactId: user?.contactId || '',
            customer_userRole: user?.userRole || '',
            customer_userType: user?.userRole || '',
            customer_eid: user?.enterpriseId || '',
        }));
    }

    // Track Successful Login [Tealium]
    const signInEventObj = {
        eventName: 'user_login',
        eventCategory: 'Sign In',
        eventAction: 'User Successfully Logged In',
        eventLabel: SignedInBy,
        customer_id: user?.email || '',
        customer_firstName: user?.firstName || '',
        customer_lastName: user?.lastName || '',
        customer_contactId: user?.contactId || '',
        customer_userRole: user?.userRole || '',
        customer_userType: user?.userRole || '',
        customer_eid: user?.enterpriseId || '',
    };

    yield put(tmDuck.actions.trackEvent(signInEventObj));

    // Update Data Layer
    if (signInMethod === 'popup') {
        yield call(updateDataLayer);
    }
}

function* watcherTrackUserSignedIn() {
    yield takeEvery([
        authActions.logUserLoggedInSuccess().type,
    ], workerTrackUserSignedIn);
}

function* watchAuthChannelActions() {
    if (typeof window !== 'undefined') {
        while (true) {
            const action = yield take(auth.authChannel);
            yield put(action);
        }
    }
}

const watchers = [
    fork(watcherSagaRouteListener),
    fork(watcherInitinterstitial),
    fork(watcherLogGuestSession),
    fork(watcherUserLogoutInvoked),
    fork(watcherUserLoginSuccess),
    fork(watcherLogHydraidUserSession),
    fork(watcherTrackUserSignedIn),
    fork(watcherClearAuthDependentState),
    fork(watchAuthChannelActions),
];

const workers = {
    checkJWT,
};

export default {
    watchers,
    workers,
};
